import { render, staticRenderFns } from "./ProjectDeleteModal.vue?vue&type=template&id=1906874a&scoped=true&"
import script from "./ProjectDeleteModal.vue?vue&type=script&lang=js&"
export * from "./ProjectDeleteModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1906874a",
  null
  
)

export default component.exports