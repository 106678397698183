<template>
	<QuestionModal
		ref="projectDeleteModal"
		title="Projekt löschen"
		question="Möchstest du das Projekt und alle enthaltenen Umfragen wirklich löschen?"
		okText="Ja, löschen"
		cancelText="Nein, abbrechen"
		@ok="ok"
		@cancel="hide"
	/>
</template>

<script>
import QuestionModal from '../../../base/QuestionModal';
import { mapActions } from 'vuex';
export default {
	name: 'ProjectDeleteModal',
	components: {
		QuestionModal
	},
	props: {
		projectID: Number
	},
	methods: {
		...mapActions(['deleteProject']),
		show: function () {
			this.$refs.projectDeleteModal.show();
		},
		hide: function () {
			this.$refs.projectDeleteModal.hide();
		},
		ok: function () {
			this.hide();
			this.deleteProject(this.projectID);

			this.$notify({
				group: 'main',
				type: 'success',
				title: 'Aktion erfolgreich',
				text: 'Das Projekt wurde erfolgreich gelöscht!'
			});
		}
	}
};
</script>

<style lang="scss" scoped></style>
