<template>
    <div v-if="showModal" class="custom-modal-mask">
        <notifications width="400px" group="input" />
        <div class="custom-modal-wrapper">
            <div class="custom-modal-container">
                <div class="custom-modal-header">
                    <h1>{{ title }}</h1>
                </div>
                <div class="custom-modal-body">
                    <h3>{{ subtitle }}</h3>
                    <input :value="value" @input="$emit('input', $event.target.value)" @keyup.enter="$emit('ok')" type="text" />

                    <h3 v-if="extraInputSubtitle != null">{{ extraInputSubtitle }} <h4 v-if="!extraInputRequired">optional</h4></h3>
                    <input v-if="extraInputSubtitle != null" v-model="extraInputValue" @keyup.enter="$emit('ok')" type="text" />

                    <div v-if="selectionSubtitle != null && selectionItems != null">
                        <h3>{{ selectionSubtitle }}</h3>
                        <ul class="selection-list">
                            <li 
                                v-for="(selection, index) in selectionItems" 
                                :key="index"
                                class="item"
                                :class="{ selected: selection.selected }"
                            >
                                <button @click="selection.selected = !selection.selected">
                                    <span>{{ selection.text }}</span>
                                    <img src="../../assets/icons/menu.svg" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="custom-modal-footer">
                    <button @click="ok" class="ok">{{ okText }}</button>
                    <button @click="$emit('cancel')" class="cancel">{{ cancelText }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'InputModal',
        props: {
            value: String,
            title: String,
            subtitle: String,
            okText: String,
            cancelText: String,

            selectionSubtitle: String,
            selectionItems: Array,

            extraInputSubtitle: String,
            extraInputRequired: Boolean
        },
        data: function() {
            return {
                showModal: false,
                extraInputValue: ''
            }
        },
        methods: {
            show: function() {
                this.showModal = true;
            },
            hide: function() {
                this.showModal = false;
            },
            ok: function() {
                if(this.value == "" || (this.extraInputRequired && this.extraInputValue == ""))
                    this.$notify({
                        group: 'input',
                        type: 'error',
                        title: 'Fehler in der Eingabe',
                        text: 'Es wurde kein Wert eingegeben!'
                    });
                else
                    this.$emit('ok');
            },
            getExtraValue() {
                return this.extraInputValue;
            },
            setExtraValue(val) {
                this.extraInputValue = val;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/modal_base.scss';

    .custom-modal-header {
        margin-bottom: 30px;

        h1 {
            @include normal-text;
            font-size: 22px;
            color: $unnamed-color-999999;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .custom-modal-body {
        margin-bottom: 40px;

        h3 {
            margin-top: 10px;
            margin-bottom: 8px;

            @include normal-text;
            color: $unnamed-color-999999;
            font-weight: normal;
            font-size: 22px;

            h4 {
                display: inline-block;
                margin-left: 10px;

                @include normal-text-15;
                color: $unnamed-color-999999;
            }
        }

        input {
            width: 100%;
            height: 48px;

            padding: 0 16px;

            border: 1px solid $unnamed-color-999999;
            background: #262626 0% 0% no-repeat padding-box;
            border: 1px solid #999999;
            border-radius: 4px;
            opacity: 1;

            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            @include normal-text;
            font-size: 22px;
            color: white;
        }

        .selection-list {
            padding: 0;
            list-style:none;
            
            .item {
                height: 48px;
                margin: 5px 0;

                background: #333333 0% 0% no-repeat padding-box;
                border: 2px solid #333333;
                border-radius: 4px;

                &:hover {
                    background: $unnamed-color-1a1a1a 0% 0% no-repeat padding-box;
                    border: 2px solid #FDC300;
                }

                &.selected {
                    background: $unnamed-color-1a1a1a 0% 0% no-repeat padding-box;
                    border: 2px solid #FDC300;

                    color: #FDC300;
                }

                button {
                    width: 100%;
                    height: 100%;

                    position: relative;
                    text-align: center;

                    background: none;
                    border: none;

                    color: $unnamed-color-999999;
                    cursor: pointer;

                    &:hover {
                        color: #FDC300;
                    }

                    span {
                        @include normal-text-22;
                        color: inherit;
                    }

                    img {
                        width: 18px;
                        height: 12px;

                        position: absolute;
                        right: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    .custom-modal-footer {
        button {
            display: block;
            width: 100%;
            height: 48px;

            @include normal-text;
            font-size: 22px;
            font-weight: bold;
            text-transform: uppercase;

            margin-bottom: 5px;

            &.ok {
                border: none;
                background: $unnamed-color-fdc300 0% 0% no-repeat padding-box;
                background: #FDC300 0% 0% no-repeat padding-box;
                border-radius: 4px;
                opacity: 1;

                cursor: pointer;
                color: $unnamed-color-000000;
            }

            &.cancel {
                border: none;
                background: $unnamed-color-333333 0% 0% no-repeat padding-box;
                background: #333333 0% 0% no-repeat padding-box;
                border-radius: 4px;
                opacity: 1;

                cursor: pointer;
                color: $unnamed-color-999999;
            }
        }
    }
</style>