<template>
	<QuestionModal
		ref="surveyDeleteModal"
		title="Umfrage löschen"
		question="Möchstest du die Umfrage und alle enthaltenen Seiten wirklich löschen?"
		okText="Ja, löschen"
		cancelText="Nein, abbrechen"
		@ok="ok"
		@cancel="hide"
	/>
</template>

<script>
import QuestionModal from '../../../base/QuestionModal';
import { mapActions } from 'vuex';
export default {
	name: 'SurveyDeleteModal',
	components: {
		QuestionModal
	},
	props: {
		projectID: Number,
		surveyID: Number
	},
	methods: {
		...mapActions(['deleteSurvey']),
		show: function () {
			this.$refs.surveyDeleteModal.show();
		},
		hide: function () {
			this.$refs.surveyDeleteModal.hide();
		},
		ok: function () {
			this.hide();
			this.deleteSurvey({ projectID: this.projectID, surveyID: this.surveyID });

			this.$notify({
				group: 'main',
				type: 'success',
				title: 'Aktion erfolgreich',
				text: 'Die Umfrage wurde erfolgreich gelöscht!'
			});
		}
	}
};
</script>

<style lang="scss" scoped></style>
