<template>
    <div v-if="showModal" class="custom-modal-mask">
        <div class="custom-modal-wrapper">
            <div class="custom-modal-container">
                <div class="custom-modal-header">
                    <h1>{{ title }}</h1>
                </div>
                <div class="custom-modal-body">
                    <p>{{ question }}</p>
                </div>
                <div class="custom-modal-footer">
                    <button @click="$emit('ok')" class="ok">{{ okText }}</button>
                    <button @click="$emit('cancel')" class="cancel">{{ cancelText }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'QuestionModal',
        props: {
            title: String,
            question: String,
            okText: String,
            cancelText: String
        },
        data: function() {
            return {
                showModal: false
            }
        },
        methods: {
            show: function() {
                this.showModal = true;
            },
            hide: function() {
                this.showModal = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/modal_base.scss';

    .custom-modal-header {
        margin-bottom: 40px;

        h1 {
            @include normal-text;
            font-size: 22px;
            color: $unnamed-color-999999;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .custom-modal-body {
        margin-bottom: 68px;

        p {
            @include normal-text;
            font-size: 22px;
            color: $unnamed-color-999999;
        }
    }

    .custom-modal-footer {
        button {
            display: block;
            width: 100%;
            height: 48px;

            @include normal-text;
            font-size: 22px;
            font-weight: bold;
            text-transform: uppercase;

            margin-bottom: 5px;

            &.ok {
                border: none;
                background: $unnamed-color-fc6027 0% 0% no-repeat padding-box;
                border-radius: 4px;
                opacity: 1;

                cursor: pointer;
                color: $unnamed-color-ffffff;
            }

            &.cancel {
                border: none;
                background: $unnamed-color-333333 0% 0% no-repeat padding-box;
                border-radius: 4px;
                opacity: 1;

                cursor: pointer;
                color: $unnamed-color-999999;
            }
        }
    }
</style>