<template>
	<div>
		<div class="sideBar">
			<h5 class="site-name">
				<img src="../assets/icons/c3_icon.svg" />
				Lead-Funnel
			</h5>

			<h1 class="title">Projekte</h1>
			<button @click="showCreateProjectModal" class="new-project-button">
				<img src="../assets/icons/add-circle-sharp.svg" />
				<span>Neues Projekt</span>
			</button>

			<ProjectList v-model="selectedProjectID" :projects="projects" />
		</div>
		<div class="content">
			<ProjectCreateModal ref="createProject" />
			<ProjectDeleteModal :projectID="selectedProjectID" ref="deleteProject" />
			<ProjectEditModal :projectID="selectedProjectID" ref="editProject" />

			<SurveyCreateModal :projectID="selectedProjectID" ref="createSurvey" />
			<SurveyDeleteModal
				:projectID="selectedProjectID"
				:surveyID="selectedSurveyID"
				ref="deleteSurvey"
			/>
			<SurveyEditModal
				:projectID="selectedProjectID"
				:survey="survey"
				ref="editSurvey"
			/>

			<div v-if="project != null">
				<h1 class="project-name">{{ project.name }}</h1>
				<div class="pages">
					<div class="title">
						<h3>Umfragen</h3>
						<button @click="showCreateSurveyModal" class="new-survey">
							<img src="../assets/icons/add-circle-sharp.svg" />
							<span>Neue Umfrage</span>
						</button>
					</div>

					<SurveyList
						:project="project"
						@export="exportSurvey"
						@edit="showEditSurveyModal($event)"
						@delete="showDeleteSurveyModal($event)"
					/>

					<div class="edit-project-container">
						<h3>Projekt</h3>
						<div class="button-container">
							<button @click="showEditProjectModal" class="edit-project">
								<img src="../assets/icons/edit.svg" />
								<span>Projekt bearbeiten</span>
							</button>
							<button @click="showDeleteProjectModal" class="delete-project">
								<img src="../assets/icons/delete.svg" />
								<span>Projekt mit allen Umfragen löschen</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ProjectCreateModal from '../components/projekte/modale/project/ProjectCreateModal';
import ProjectDeleteModal from '../components/projekte/modale/project/ProjectDeleteModal';
import ProjectEditModal from '../components/projekte/modale/project/ProjectEditModal';

import SurveyCreateModal from '../components/projekte/modale/survey/SurveyCreateModal';
import SurveyDeleteModal from '../components/projekte/modale/survey/SurveyDeleteModal';
import SurveyEditModal from '../components/projekte/modale/survey/SurveyEditModal';

import ProjectList from '../components/projekte/ProjectList';
import SurveyList from '../components/projekte/SurveyList';

import FileDownloader from 'js-file-download';

import { mapGetters, mapActions } from 'vuex';
export default {
	name: 'Projekte',
	components: {
		ProjectCreateModal,
		ProjectDeleteModal,
		ProjectEditModal,

		SurveyCreateModal,
		SurveyDeleteModal,
		SurveyEditModal,

		ProjectList,
		SurveyList
	},
	data: function () {
		return {
			selectedProjectID: this.$route.params?.projectID ?? null,
			selectedSurveyID: null
		};
	},
	computed: {
		...mapGetters(['projects', 'projectsLoaded']),
		project: function () {
			if (this.selectedProjectID == null) return null;

			return this.projects.find((x) => x.id == this.selectedProjectID);
		},
		survey: function () {
			if (this.project == null || this.selectedSurveyID == null) return null;

			return this.project.surveys.find((x) => x.id == this.selectedSurveyID);
		}
	},
	watch: {
		project: function () {
			if (this.project != undefined && this.project.surveys.length == 0)
				this.getSurveysByProject(this.project.id);
		}
	},
	methods: {
		...mapActions(['getAllProjects', 'getSurveysByProject']),
		showCreateProjectModal() {
			this.$refs.createProject.show();
		},
		showDeleteProjectModal() {
			this.$refs.deleteProject.show();
		},
		showEditProjectModal() {
			this.$refs.editProject.show();
		},

		showCreateSurveyModal() {
			this.$refs.createSurvey.show();
		},
		showDeleteSurveyModal(surveyID) {
			this.selectedSurveyID = surveyID;
			this.$refs.deleteSurvey.show();
		},
		showEditSurveyModal(surveyID) {
			this.selectedSurveyID = surveyID;

			this.$nextTick(() => {
				this.$refs.editSurvey.show();
			});
		},
		exportSurvey: function (surveyID) {
			this.selectedSurveyID = surveyID;

			this.$nextTick(() => {
				FileDownloader(
					JSON.stringify({
						id: this.project.id,
						name: this.project.name,
						survey: this.survey
					}),
					'umfrage.json'
				);
			});
		}
	},
	mounted: function () {
		if (!this.projectsLoaded) this.getAllProjects();
	}
};
</script>

<style lang="scss" scoped>
.sideBar {
	width: 400px;
	height: 100%;

	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;

	background-color: $unnamed-color-1a1a1a;
	overflow-y: auto;

	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0 !important;
	}

	padding: 0 20px 0 20px;

	.site-name {
		margin-top: 40px;
		margin-bottom: 8px;

		@include flex-left;

		@include bold-text-uppercase-15;
		color: $unnamed-color-999999;

		img {
			width: 16px;
			height: 16px;
			margin-right: 5px;
		}
	}

	.title {
		margin-top: 0;
		margin-bottom: 0;

		@include bold-text-uppercase-35;
		color: $unnamed-color-ffffff;
	}

	.new-project-button {
		margin: 40px 0 40px 0;
		height: 48px;
		width: 100%;

		position: relative;
		text-align: center;

		background: $unnamed-color-fdc300 0% 0% no-repeat padding-box;
		border-radius: 4px;
		border: none;

		cursor: pointer;
		color: $unnamed-color-000000;

		span {
			@include bold-text-uppercase-22;
			color: inherit;
		}

		img {
			width: 24px;
			height: 24px;

			position: absolute;
			left: 20px;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			background: #333333 0% 0% no-repeat padding-box;
			border: 2px solid black;
			border-radius: 4px;

			color: $unnamed-color-ffffff;
		}
	}
}

.content {
	display: block;

	position: absolute;
	height: auto;

	bottom: 0;
	top: 0;
	left: 0;
	right: 0;

	margin-left: 400px;
	background-color: green;
	padding: 60px 40px;

	background-color: $unnamed-color-000000;
	overflow-y: auto;

	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0 !important;
	}

	.project-name {
		margin-bottom: 56px;

		@include bold-text-uppercase-35;
		color: $unnamed-color-ffffff;
	}

	.title {
		height: 70px;

		@include flex-between-top;

		border-bottom: 1px solid #404040;

		h3 {
			@include bold-text-uppercase-22;
			color: $unnamed-color-999999;
		}

		.new-survey {
			height: 48px;
			width: 226px;

			padding-right: 20px;

			position: relative;
			text-align: right;

			background: $unnamed-color-fdc300 0% 0% no-repeat padding-box;
			border-radius: 4px;
			border: none;

			color: $unnamed-color-000000;
			cursor: pointer;

			span {
				@include bold-text-uppercase-22;
				color: inherit;
			}

			img {
				width: 24px;
				height: 24px;

				position: absolute;
				left: 20px;
				top: 50%;
				transform: translateY(-50%);
			}

			&:hover {
				background: #333333 0% 0% no-repeat padding-box;
				border: 2px solid black;
				border-radius: 4px;

				color: white;
			}
		}
	}

	.edit-project-container {
		margin-bottom: 60px;

		h3 {
			margin-bottom: 20px;

			@include normal-text;
			font-size: 22px;
			font-weight: bold;
			text-transform: uppercase;
			color: $unnamed-color-999999;
		}

		.button-container {
			@include flex-left;

			.edit-project {
				margin-right: 10px;

				height: 48px;
				width: 290px;

				padding-right: 20px;

				position: relative;
				text-align: right;

				background: $unnamed-color-333333 0% 0% no-repeat padding-box;
				border-radius: 4px;
				opacity: 1;
				border: none;

				cursor: pointer;
				color: $unnamed-color-999999;

				span {
					@include normal-text;
					color: inherit;
					font-size: 22px;
					font-weight: bold;
					text-transform: uppercase;
				}

				img {
					width: 24px;
					height: 24px;

					position: absolute;
					left: 20px;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			.delete-project {
				height: 48px;
				width: 480px;

				padding-right: 20px;

				position: relative;
				text-align: right;

				background: #321308 0% 0% no-repeat padding-box;
				border-radius: 4px;
				opacity: 1;
				border: none;

				cursor: pointer;
				color: #fc6027;

				span {
					@include normal-text;
					color: inherit;
					font-size: 22px;
					font-weight: bold;
					text-transform: uppercase;
				}

				img {
					width: 24px;
					height: 24px;

					position: absolute;
					left: 20px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
}
</style>
