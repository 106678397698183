<template>
	<InputModal
		ref="projectCreateModal"
		v-model="projectName"
		title="Projekt erstellen"
		subtitle="Titel"
		okText="Projekt erstellen"
		cancelText="Abbrechen"
		@ok="ok"
		@cancel="hide"
	/>
</template>

<script>
import InputModal from '../../../base/InputModal';
import { mapActions } from 'vuex';
export default {
	name: 'ProjectCreateModal',
	components: {
		InputModal
	},
	data: function () {
		return {
			showModal: false,
			projectName: ''
		};
	},
	methods: {
		...mapActions(['addProject']),
		show: function () {
			this.$refs.projectCreateModal.show();
		},
		hide: function () {
			this.$refs.projectCreateModal.hide();
			this.projectName = '';
		},
		ok: function () {
			var projectName = this.projectName;
			this.hide();
			this.addProject(projectName);

			this.$notify({
				group: 'main',
				type: 'success',
				title: 'Aktion erfolgreich',
				text: 'Das Projekt wurde erfolgreich erstellt!'
			});
		}
	}
};
</script>

<style lang="scss" scoped></style>
