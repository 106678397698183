<template>
	<ul class="survey-list">
		<li v-for="survey in project.surveys" :key="survey.id" class="item">
			<a
				@click="
					$router.push({
						name: 'Editor',
						params: { projectID: project.id, surveyID: survey.id }
					})
				"
				href="javascript:void(0)"
				>{{ survey.name }}</a
			>
			<div>
				<button
					@click="
						$router.push({
							name: 'Result',
							params: { projectID: project.id, surveyID: survey.id }
						})
					"
					class="result-survey"
				>
					<img src="../../assets/icons/result.svg" />
				</button>
				<button @click="$emit('export', survey.id)" class="export-survey">
					<img src="../../assets/icons/export.svg" />
				</button>
				<button @click="$emit('edit', survey.id)" class="edit-survey">
					<img src="../../assets/icons/edit.svg" />
				</button>
				<button @click="$emit('delete', survey.id)" class="delete-survey">
					<img src="../../assets/icons/delete.svg" />
				</button>
			</div>
		</li>
		<li v-if="project && project.surveys.length == 0" class="item">
			<p>Keine Umfrage vorhanden</p>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'SurveyList',
	props: {
		project: Object
	}
};
</script>

<style lang="scss" scoped>
.survey-list {
	padding: 0;
	list-style: none;
	margin-bottom: 40px;

	.item {
		padding: 0;
		margin: 0;
		height: 88px;

		@include flex-between-center;

		border-bottom: 1px solid #404040;

		a {
			margin: 0;
			padding: 0;

			@include normal-text-22;
			color: $unnamed-color-999999;
			text-decoration: none;
		}

		p {
			margin: 0;
			padding: 0;

			@include normal-text-22;
			color: $unnamed-color-999999;
		}

		.result-survey {
			margin-right: 5px;

			height: 48px;
			width: 48px;

			background: $unnamed-color-333333 0% 0% no-repeat padding-box;
			border-radius: 4px;
			border: none;

			cursor: pointer;
			color: $unnamed-color-999999;

			img {
				width: 24px;
				height: 24px;

				margin-top: 4px;
			}
		}

		.export-survey {
			margin-right: 5px;

			height: 48px;
			width: 48px;

			background: $unnamed-color-333333 0% 0% no-repeat padding-box;
			border-radius: 4px;
			border: none;

			cursor: pointer;
			color: $unnamed-color-999999;

			img {
				width: 24px;
				height: 24px;

				margin-top: 4px;
			}
		}

		.edit-survey {
			margin-right: 5px;

			height: 48px;
			width: 48px;

			background: $unnamed-color-333333 0% 0% no-repeat padding-box;
			border-radius: 4px;
			border: none;

			cursor: pointer;
			color: $unnamed-color-999999;

			img {
				margin-top: 4px;
			}
		}

		.delete-survey {
			height: 48px;
			width: 48px;

			background: #321308 0% 0% no-repeat padding-box;
			border-radius: 4px;
			border: none;

			cursor: pointer;
			color: #fc6027;

			img {
				margin-top: 4px;
			}
		}
	}
}
</style>
