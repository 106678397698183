<template>
	<InputModal
		ref="projectEditModal"
		v-model="projectName"
		title="Projekt bearbeiten"
		subtitle="Titel"
		okText="Änderung speichern"
		cancelText="Abbrechen"
		@ok="ok"
		@cancel="hide"
	/>
</template>

<script>
import InputModal from '../../../base/InputModal';
import { mapActions } from 'vuex';
export default {
	name: 'ProjectEditModal',
	components: {
		InputModal
	},
	props: {
		projectID: Number
	},
	data: function () {
		return {
			projectName: ''
		};
	},
	methods: {
		...mapActions(['editProject']),
		show: function () {
			this.$refs.projectEditModal.show();
		},
		hide: function () {
			this.$refs.projectEditModal.hide();
			this.projectName = '';
		},
		ok: function () {
			var projectName = this.projectName;
			this.hide();
			this.editProject({ name: projectName, projectID: this.projectID });

			this.$notify({
				group: 'main',
				type: 'success',
				title: 'Aktion erfolgreich',
				text: 'Das Projekt wurde erfolgreich bearbeitet!'
			});
		}
	}
};
</script>

<style lang="scss" scoped></style>
