<template>
	<ul class="project-list">
		<li
			v-for="project in projects"
			:key="project.id"
			:class="value != project.id ? 'project' : 'selected-project'"
		>
			<a href="javascript:void(0)" @click="$emit('input', project.id)">
				{{ project.name }}
			</a>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'ProjectList',
	props: {
		projects: Array,
		value: Number
	}
};
</script>

<style lang="scss" scoped>
.project-list {
	padding: 0;
	list-style: none;

	.project {
		height: 48px;
		margin: 5px 0;

		@include flex-left;

		background: #333333 0% 0% no-repeat padding-box;
		border: 2px solid #333333;
		border-radius: 4px;

		color: $unnamed-color-999999;

		&:hover {
			background: $unnamed-color-1a1a1a 0% 0% no-repeat padding-box;
			border: 2px solid #fdc300;
			border-radius: 4px;
			opacity: 1;

			color: #fdc300;
		}

		a {
			width: 100%;
			padding: 20px;

			@include normal-text-22;
			color: inherit;
			text-decoration: none;
		}
	}

	.selected-project {
		height: 48px;
		margin: 5px 0;

		@include flex-left;

		background: $unnamed-color-1a1a1a 0% 0% no-repeat padding-box;
		border: 2px solid #fdc300;
		border-radius: 4px;

		color: $unnamed-color-999999;

		&:hover {
			color: #fdc300;
		}

		a {
			width: 100%;
			padding: 20px;

			@include normal-text-22;
			color: inherit;
			text-decoration: none;
		}
	}
}
</style>
