<template>
	<InputModal
		ref="surveyCreateModal"
		v-model="surveyName"
		title="Neue Umfrage"
		subtitle="Titel"
		okText="Umfrage erstellen"
		cancelText="Abbrechen"
		@ok="ok"
		@cancel="hide"
		extraInputSubtitle="Telefonnummer in der Umfrage"
		selectionSubtitle="Abfrage am Ende"
		:selectionItems="selectedFields"
	/>
</template>

<script>
import InputModal from '../../../base/InputModal';
import { mapActions } from 'vuex';
export default {
	name: 'SurveyCreateModal',
	components: {
		InputModal
	},
	props: {
		projectID: Number
	},
	data: function () {
		return {
			surveyName: '',
			selectedFields: [
				{ type: 1, text: 'E-Mail', selected: false },
				{ type: 2, text: 'Anrede', selected: false },
				{ type: 4, text: 'Vorname', selected: false },
				{ type: 8, text: 'Nachname', selected: false },
				{ type: 16, text: 'Weitere Informationen', selected: false }
			]
		};
	},
	methods: {
		...mapActions(['addSurvey']),
		show: function () {
			this.$refs.surveyCreateModal.show();
		},
		hide: function () {
			this.$refs.surveyCreateModal.hide();

			this.surveyName = '';
			this.selectedFields = [
				{ type: 1, text: 'E-Mail', selected: false },
				{ type: 2, text: 'Anrede', selected: false },
				{ type: 4, text: 'Vorname', selected: false },
				{ type: 8, text: 'Nachname', selected: false },
				{ type: 16, text: 'Weitere Informationen', selected: false }
			];
			this.$refs.surveyCreateModal.setExtraValue('');
		},
		ok: function () {
			var surveyName = this.surveyName;
			var information = 0;
			var phonenumber = this.$refs.surveyCreateModal.getExtraValue();

			this.selectedFields.forEach((element) => {
				if (element.selected)
					if (information == 0) information = element.type;
					else information = information | element.type;
			});

			this.hide();
			this.addSurvey({
				name: surveyName,
				information,
				phonenumber,
				projectID: this.projectID
			});

			this.$notify({
				group: 'main',
				type: 'success',
				title: 'Aktion erfolgreich',
				text: 'Die Umfrage wurde erfolgreich erstellt!'
			});
		}
	}
};
</script>

<style lang="scss" scoped></style>
